import CModal from "../../../components/CModal";
import RoleCreateForm from "./RoleCreateForm";

export default function RoleCreateModal() {
  return (
    <CModal title="Add role" returnUrl="/roles">
      <RoleCreateForm />
    </CModal>
  );
}
