import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";

import { OperationPage } from "../../../lib/api/types";

export const fetchOperationsPage = (pageSize: number): Promise<OperationPage> => {
  return httpClientInstance.listOperation({
    page_size: pageSize,
    filter: `request.action!="custom/heartbeat"`,
  });
};

type UseOperationsPageOptions = {
  pageSize: number;
};

export const useGetOperationsPage = ({ pageSize }: UseOperationsPageOptions) => {
  const queryKey = useMemo(() => ["OperationsPage", pageSize], [pageSize]);

  const { data, error, isError, isLoading } = useQuery({
    queryKey,
    queryFn: () => fetchOperationsPage(pageSize),
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching OperationsPage:", error);
    }
  }, [error]);
  return { data: data || null, error: isError ? error : null, isLoading };
};
