import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { CredentialVariant, UserVariant } from "../../../lib/api/types";

export interface RoleFormData {
  name: string;
  user: UserVariant;
  credential: CredentialVariant | CredentialVariant[];
  labels: any;
  notes: any;
}
interface SubmitError {
  message: string;
}

async function submitFormData(formData: RoleFormData): Promise<void> {
  await httpClientInstance.create("Role", `roles/${formData.name}`, {
    ...formData,
  });
}

export function useAddRoleMutation(): UseMutationResult<any, SubmitError, RoleFormData> {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Role Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting Role form:", error.message);
    },
  });
}
