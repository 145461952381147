import React, { useState, useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  DataTableRow,
} from "@carbon/react";

import { ParseName } from "../../../lib/api/name";
import { AddressVariant, BasicState } from "../../../lib/api/types";
import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import { formatDate } from "../../../utils/formater";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";
import { useAddressBalances } from "../api/getAddressBalances";
import { useGetAssetPage } from "../../../features/assetManagement/api/getAssetPage";
import { useAssetPrices } from "../api/getAssetPrices";
import { convertBalance } from "../../../features/depositsManagement/api/convertBalance";

export interface AddressListItem {
  account?: string;
  name?: string;
  variant?: AddressVariant;
  state?: BasicState;
  create_time?: string;
  notes?: string | { description?: string };
}

interface AddressTableProps {
  addresses: AddressListItem[];
}

const AddressTable: React.FC<AddressTableProps> = ({ addresses }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Integrate the usePagination hook
  const { page, pageSize, pageSizes, totalItems, setTotalItems, handlePageChange } =
    usePagination();

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "account", header: "Account" },
    { key: "chain", header: "Chain" },
    { key: "name", header: "Name" },
    { key: "balance", header: "Balance" },
    { key: "notes", header: "Description" },
    { key: "variant", header: "Variant" },
  ];

  const extractDescription = (jsonString: string): string | undefined => {
    try {
      const data = JSON.parse(jsonString);
      return data.description;
    } catch (error) {
      console.error("Invalid JSON string provided:", error);
      return undefined;
    }
  };

  const filteredRows = addresses
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time ?? "") || "",
      notes:
        item.notes && typeof item.notes === "object" && item.notes.description
          ? item.notes.description
          : extractDescription(item?.notes?.toString() ?? "") || "",
    }))
    .filter((row) => {
      return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }) as { [key: string]: any }[];

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const getFirstElement = (input: any): string => {
    if (typeof input !== "string") return "";
    const parts = input.split("/");
    return parts[0];
  };

  const renderCellContent = (cell: any, row: DataTableRow<any>): any => {
    const rendererType = getFirstElement(cell.value);
    switch (cell.info.header) {
      case "name":
        return (
          <TruncateText
            text={cell.value.toString()}
            maxLength={30}
            isBlockChainExplorerEnabled={true}
          />
        );
      case "account":
        if (rendererType === "accounts") {
          return <>{ParseName([cell.value ?? ""], "Account").resourceId || ""}</>;
        }
        return <>{cell.value}</>;
      case "balance":
        return (
          <AddressBalance address={row.cells.find((cell) => cell.info.header === "name")?.value} />
        );
      case "chain":
        return (
          (IconCellRenderer({
            value: ParseName([row.cells[3].value ?? ""], "Address").parentId || "",
          }) as ReactElement) || <></>
        );
      default:
        return typeof cell.value === "object" ? JSON.stringify(cell.value) : cell.value;
    }
  };

  const handleRowClick = (rowId: string) => {
    navigate(`/address/${rowId}`);
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>
      <DataTable
        size="md"
        // @ts-expect-error error
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  // @ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row.cells[3].value)}
                  style={{ cursor: "pointer" }} // Optional: Change cursor to pointer on hover
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell, row)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default AddressTable;

function AddressBalance({ address }: { address: string | undefined }) {
  const parsedName = ParseName([address ?? ""], "Address");
  const chainId = parsedName.parentId || "";
  const addressId = parsedName.resourceId || "";

  const {
    data: addressBalances,
    isLoading: isLoadingAddressBalances,
    error: errorAddressBalances,
  } = useAddressBalances({ addressId, chainId, pageSize: 1000 });
  const { data: assetsPage } = useGetAssetPage({ pageSize: 1000 });
  const { data: assetsPrices } = useAssetPrices();

  const balance = addressBalances?.balances
    ?.map((item) => {
      const numericBalance = Number(
        convertBalance(
          Number(item.balance),
          assetsPage?.assets?.find((a) => a.contract === item.asset_id)?.decimals,
        ),
      );
      const assetPrice = assetsPrices?.find(
        (p) => p.asset_name === `chains/${chainId}/assets/${item.asset_id}`,
      )?.price;

      return assetPrice ? assetPrice * numericBalance : 0;
    })
    .reduce((acc, val) => acc + val, 0);

  if (errorAddressBalances || isLoadingAddressBalances) return <span>N/A</span>;

  return <span>{balance?.toFixed(2)} USD</span>;
}
