import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";

import { User, UserPage } from "../../../lib/api/types";
import { WebauthnUser } from "../../../lib/api/webauthn";

function fetchUsers(page: number, pageSize: number, filter: string = ""): Promise<UserPage> {
  return httpClientInstance.listUser({ page_size: pageSize, filter: filter });
}

function fetchUser(username: string): Promise<User> {
  return httpClientInstance.getUser([username]) as Promise<User>;
}

function fetchWebAuthnUser(username: string): Promise<WebauthnUser> {
  return httpClientInstance.getUserWebauthnCreds(username);
}

function deleteUser(username: string) {
  return httpClientInstance.delete("User", username);
}

export function useUsers(page: number, pageSize: number, filter: string): UserPage | null {
  const queryKey = useMemo(() => ["users", page, pageSize, filter], [page, pageSize, filter]);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchUsers(page, pageSize, filter),
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching users:", error);
    }
  }, [error]);

  return data || null;
}

export function useUser(): User | null {
  const username = localStorage.getItem("user") || "";
  const queryKey = useMemo(() => ["user", username], [username]);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchUser(username),
    enabled: !!username,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching user:", error);
    }
  }, [error]);

  return data || null;
}

export function useUserById(username: string): User | null {
  const queryKey = useMemo(() => ["user", username], [username]);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchUser(username),
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching user:", error);
    }
  }, [error]);

  return data || null;
}

export function useWebAuthnUser(): WebauthnUser | null {
  const username = localStorage.getItem("user") || "";
  const queryKey = useMemo(() => ["webAuthnUser", username], [username]);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchWebAuthnUser(username),
    enabled: !!username,
  });

  const webauthnClient = httpClientInstance.webauthnClient;

  if (data) {
    webauthnClient.setUser(data);
  } else {
    console.log("webauthnUser is not set ");
  }

  useEffect(() => {
    if (error) {
      console.error("Error fetching WebAuthn user:", error);
    }
  }, [error]);

  return data || null;
}

export function useWebAuthnUserById(username: string): WebauthnUser | null {
  const queryKey = useMemo(() => ["webAuthnUser", username], [username]);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchWebAuthnUser(username),
    enabled: !!username,
  });

  const webauthnClient = httpClientInstance.webauthnClient;

  if (data) {
    webauthnClient.setUser(data);
  } else {
    console.log("webauthnUser is not set ");
  }

  useEffect(() => {
    if (error) {
      console.error("Error fetching WebAuthn user:", error);
    }
  }, [error]);

  return data || null;
}

export function useDeleteUser() {
  return useMutation({
    mutationFn: deleteUser,
  });
}
