import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";

import { Operation } from "../../../lib/api/types";

async function fetchOperationDetails(name: string): Promise<Operation> {
  return httpClientInstance.getOperation(name);
}

export const useOperationDetails = (name: string) => {
  const queryKey = useMemo(() => ["depositsDetails", name], [name]);

  const { data, error, isError, isLoading } = useQuery<Operation, Error>({
    queryKey,
    queryFn: () => fetchOperationDetails(name),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (isError && error) {
      console.error("Error fetching operationDetails:", error);
    }
  }, [isError, error]);

  return {
    data: data || null,
    error: isError ? error : null,
    isLoading: isLoading,
  };
};
