import React, { useState, useEffect, useMemo } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  DataTableCell,
} from "@carbon/react";

import { formatDate } from "../../../utils/formater";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useAddressBalances } from "../api/getAddressBalances";
import { useGetAssetPage } from "../../assetManagement/api/getAssetPage";
import { convertBalance } from "../../depositsManagement/api/convertBalance";
import { useAssetPrices } from "../api/getAssetPrices";
import { Autoscaling } from "@carbon/react/icons";
import { getExplorerUrl } from "../../../utils/BlockchainExplorerManager";

const AddressBalancesTable = ({ addressId, chainId }: { addressId: string; chainId: string }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    handlePageChange,
    setPage,
    pageToken,
    setPageToken,
    setPrevPageTokens,
  } = usePagination();

  const {
    data: addressBalances,
    isLoading: isLoadingAddressBalances,
    error: errorAddressBalances,
  } = useAddressBalances({ addressId, chainId, pageSize, pageToken });
  const { data: assetsPage } = useGetAssetPage({ pageSize: 1000 });
  const { data: assetsPrices } = useAssetPrices();

  useEffect(() => {
    if (addressBalances?.next_page_token) {
      setPageToken(addressBalances.next_page_token);
    }
  }, [addressBalances, setPageToken]);

  // useEffect(() => {
  //   if (addressBalances && addressBalances.total_size !== undefined) {
  //     setTotalItems(addressBalances.total_size);
  //   }
  // }, [addressBalances, setTotalItems]);

  const headers = useMemo(
    () => [
      { key: "update_time", header: "Updated" },
      { key: "balance", header: "Balance" },
      { key: "contract", header: "Asset" },
      { key: "usd_value", header: "Balance (USD)" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      (addressBalances?.balances || []).map((item, index) => {
        const numericBalance = Number(
          convertBalance(
            Number(item.balance),
            assetsPage?.assets?.find((a) => a.contract === item.asset_id)?.decimals,
          ),
        );

        const assetPrice = assetsPrices?.find(
          (p) => p.asset_name === `chains/${chainId}/assets/${item.asset_id}`,
        )?.price;

        return {
          id: `row-${index}`,
          ...item,
          update_time: formatDate(item.update_time ?? "") || "",
          contract: item.contract || item.asset_id,
          balance: `${numericBalance}`,
          usd_value: assetPrice ? `${(assetPrice * numericBalance).toFixed(2)} USD` : "Unknown",
        };
      }),
    [addressBalances?.balances, assetsPage?.assets, assetsPrices, chainId],
  );

  const filteredRows = useMemo(() => {
    if (!searchQuery) return rows;
    return rows.filter((row) =>
      Object.values(row).some(
        (value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) || false,
      ),
    );
  }, [rows, searchQuery]);

  if (isLoadingAddressBalances) {
    return <div>Loading...</div>;
  }

  if (errorAddressBalances) {
    return <div>Error: {errorAddressBalances.message}</div>;
  }

  const renderCellContent = (cell: DataTableCell<any>): JSX.Element => {
    switch (cell.info.header) {
      case "contract":
        if (chainId !== cell.value)
          return <RenderSymbolCell contract={cell.value} chain={chainId} />;
        else return <span>{cell.value}</span>;
      default:
        return <span>{cell.value}</span>;
    }
  };

  return (
    <div>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const query = event.target.value;
              setSearchQuery(query);
              setPage(1);
              setPageToken(undefined);
              setPrevPageTokens([]);
            }}
            expanded
            placeholder="Search"
            aria-label="Search transactions"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // @ts-expect-error error
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AddressBalancesTable;

function RenderSymbolCell(props: { chain: string; contract: string }) {
  const { chain, contract } = props;
  function navigateToBlockChainExplorer(e: React.MouseEvent | React.KeyboardEvent) {
    e.stopPropagation();

    const url = getExplorerUrl(chain, "token", contract);

    window.open(url, "_blank", "noopener,noreferrer");
  }

  return (
    <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
      <span>{contract}</span>
      <span
        onClick={navigateToBlockChainExplorer}
        onKeyDown={navigateToBlockChainExplorer}
        role="button"
        tabIndex={0}
        style={{ cursor: "pointer" }}
        title="Navigate to explorer"
      >
        <Autoscaling />
      </span>
    </div>
  );
}
