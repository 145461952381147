import { useQuery } from "@tanstack/react-query";
import { cordialApisClient } from "../../../lib/cordialapis/api";
import { useMemo } from "react";
import useTreasury from "../../../features/treasuryManagement/getTreasuries";
import { AssetPricesPage } from "../../../lib/cordialapis/oracle/types";
import { ParseName } from "../../../lib/api/name";

function fetchAssetPrices(treasuryId?: string): Promise<AssetPricesPage> {
  return cordialApisClient.fetchAssetPrices(treasuryId);
}

export function useAssetPrices() {
  const { data: treasuryPage, isLoading: isLoadingTreasury, error: errorTreasury } = useTreasury();

  const queryKey = useMemo(() => ["AssetPrices"], []);

  const { data, error, isLoading, isError } = useQuery<AssetPricesPage, Error>({
    queryKey,
    queryFn: () =>
      fetchAssetPrices(
        treasuryPage?.treasuries?.[0]?.name
          ? ParseName(treasuryPage?.treasuries[0].name, "Treasury").resourceId
          : undefined,
      ),
    refetchInterval: 10000,
  });

  return {
    data: data || null,
    error: isError ? error : errorTreasury ?? null,
    isLoading: isLoading || isLoadingTreasury,
  };
}
