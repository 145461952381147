import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Role } from "../../../lib/api/types";

export interface UpdateRoleFormData {
  name: string;
  roleData: Role;
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateRoleFormData): Promise<void> {
  console.log("form data", formData);
  await httpClientInstance.update("Role", formData.name, {
    ...formData.roleData,
  });
}

export function useModifyRoleMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateRoleFormData>,
): UseMutationResult<void, SubmitError, UpdateRoleFormData> {
  return useMutation<void, SubmitError, UpdateRoleFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateRoleFormData, context: unknown) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateRoleFormData, context: unknown) => {
      console.error("Error submitting role modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
