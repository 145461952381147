import React, { useEffect } from "react";
import { ContentSwitcher, Switch, FilterableMultiSelect, NumberInput } from "@carbon/react";
import { Field, FieldProps } from "formik";
import { ItemBase } from "@carbon/react/lib/components/MultiSelect/MultiSelectPropTypes";

interface SelectItem extends ItemBase {
  // interface SelectItem {
  label: string;
  value: string;
}

interface UserGroupSelectorProps {
  viewMode: string;
  groupOptions: SelectItem[];
  userOptions: SelectItem[];
  setViewMode: (mode: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setSelectedGroups: (groups: SelectItem[]) => void;
  setFilteredUsers: (users: SelectItem[]) => void;
  selectedRow?: any;
}

// A number of these 'filter' fields on API types can be optional, string, or []string.
// To make logic easier, we cast all to string[]
export function filterAsArray(filter: undefined | string | string[]): string[] {
  if (filter == undefined) {
    filter = [];
  }
  if (typeof filter == "string") {
    filter = [filter];
  }
  return filter;
}

const UserGroupSelector: React.FC<UserGroupSelectorProps> = ({
  viewMode,
  groupOptions,
  userOptions,
  setViewMode,
  setFieldValue,
  setSelectedGroups,
  setFilteredUsers,
  selectedRow,
}) => {
  useEffect(() => {
    if (selectedRow) {
      let initiate = filterAsArray(selectedRow.initiate);
      // cast to array
      if (initiate == undefined) {
        initiate = [];
      }
      if (typeof initiate == "string") {
        initiate = [initiate];
      }
      if (initiate) {
        if (initiate.some((item: string) => item.startsWith("roles/"))) {
          setViewMode("group");
          setFieldValue("viewMode", "group");

          const initialGroups = initiate.map((role: string) => ({
            label: role.split("/").pop()!,
            value: role,
          }));

          setSelectedGroups(initialGroups);
          setFieldValue("group", initialGroups.map((item) => item.value).join(","));
        } else if (initiate.some((item: string) => item.startsWith("users/"))) {
          setViewMode("user");
          setFieldValue("viewMode", "user");

          const initialUsers = initiate.map((user: string) => ({
            label: user.split("/").pop()!,
            value: user,
          }));

          setFilteredUsers(initialUsers);
          setFieldValue("user", initialUsers.map((item) => item.value).join(","));
        } else {
          setViewMode("none");
          setFieldValue("viewMode", "none");
        }

        // Set initial value for number of approvers
        if (selectedRow.approvals !== undefined) {
          setFieldValue("numberOfApprovers", selectedRow.approvals);
        }
      }
    }
  }, [selectedRow, setFieldValue, setSelectedGroups, setFilteredUsers, setViewMode]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>Select Users, Roles, or None</div>
        <ContentSwitcher
          onChange={(e) => {
            const selectedViewMode = e.name;
            setViewMode(selectedViewMode as string);
            setFieldValue("viewMode", selectedViewMode);
            setFieldValue("group", "");
            setFieldValue("user", "");
            //  setFieldValue("numberOfApprovers", 0); // Reset numberOfApprovers to 0
            setSelectedGroups([]);
            setFilteredUsers([]);
          }}
          selectedIndex={viewMode === "user" ? 0 : viewMode === "group" ? 1 : 2}
          size="sm"
        >
          <Switch name="user" text="User" />
          <Switch name="group" text="Role" />
          <Switch name="none" text="None" />
        </ContentSwitcher>
      </div>

      {viewMode === "group" && (
        <div style={{ marginBottom: "20px" }}>
          <FilterableMultiSelect
            key="group"
            id="group"
            titleText="Select Role"
            placeholder="Choose a group"
            items={groupOptions}
            compareItems={() => 1}
            sortItems={(args) => args}
            // TODO: can we just use this prop to filter natively?
            filterItems={(_items: any[]) => {
              return _items;
            }}
            initialSelectedItems={
              filterAsArray(selectedRow?.initiate)
                ?.filter((item: string) => item.startsWith("roles/"))
                .map((role: string) => ({
                  label: role.split("/").pop(),
                  value: role,
                  disabled: false,
                })) || []
            }
            itemToString={(item) => (item ? (item as any).label : "")}
            onChange={(e) => {
              const selectedGroupsValues = e.selectedItems.map((item: SelectItem) => item);
              setSelectedGroups(selectedGroupsValues);
              setFieldValue("group", selectedGroupsValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}

      {viewMode === "user" && (
        <div style={{ marginTop: "20px" }}>
          <FilterableMultiSelect
            key="user"
            id="user"
            titleText="Select User"
            placeholder="Choose a user"
            compareItems={() => 1}
            sortItems={(args) => args}
            // TODO: can we just use this prop to filter natively?
            filterItems={(_items: any[]) => {
              return _items;
            }}
            items={userOptions}
            initialSelectedItems={
              filterAsArray(selectedRow?.initiate)
                ?.filter((item: string) => item.startsWith("users/"))
                .map((user: string) => ({
                  label: user.split("/").pop(),
                  value: user,
                })) || []
            }
            itemToString={(item) => (item ? item.label : "")}
            onChange={(e) => {
              const selectedUserValues = e.selectedItems.map((item: SelectItem) => item);
              setFilteredUsers(selectedUserValues);
              setFieldValue("user", selectedUserValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}

      {(viewMode === "user" || viewMode === "group") && (
        <div style={{ marginTop: "20px" }}>
          <div style={{ paddingBottom: "10px" }}>Number of Approvers</div>
          <Field name="numberOfApprovers">
            {({ field, form }: FieldProps) => (
              <NumberInput
                style={{ width: "500px" }}
                id="numberOfApprovers"
                name="numberOfApprovers"
                min={0}
                value={field.value || 0}
                onChange={(event, { value }) => {
                  const numericValue = Number(value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    form.setFieldValue(field.name, numericValue);
                  } else {
                    form.setFieldValue(field.name, 0);
                  }
                }}
                invalid={!!form.errors.numberOfApprovers}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

export default UserGroupSelector;
