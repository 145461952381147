import { TextInput } from "@carbon/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";

import { MultiColumnRow } from "../../../components/form";
import { useNotificationStore } from "../../../stores/notifications";
import { useCredential } from "../api/getCredential";
import { UpdateCredentialFormData, useModifyCredentialMutation } from "../api/updateCredential";

interface CredentialEditFormProps {
  credentialId: string;
  onClose: () => void;
}

const RoleEditForm: React.FC<CredentialEditFormProps> = ({ credentialId, onClose }) => {
  const { mutate } = useModifyCredentialMutation();
  const { addNotification } = useNotificationStore();

  const credential = useCredential(credentialId);

  if (!credential) {
    return <div>Error loading credential details...</div>;
  }

  const initialValues: UpdateCredentialFormData = {
    name: credential?.name || "",
    credentialData: credential || {},
  };

  const onSubmit = async (
    values: UpdateCredentialFormData,
    { setSubmitting }: FormikHelpers<UpdateCredentialFormData>,
  ) => {
    try {
      mutate(values, {
        onError: (err: any) => {
          addNotification({ type: "error", title: "Error", message: `Error: ${err.message}` });
        },
        onSuccess: () => {
          addNotification({
            type: "success",
            title: "Success",
            message: "Credential updated successfully",
          });
          onClose();
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form id="credential-edit-form">
          <MultiColumnRow>
            <h5 style={{ paddingBottom: "15px" }}>{values.name || "NA"}</h5>
          </MultiColumnRow>

          {/* <MultiColumnRow>
            <TextInput
              id="variant"
              name="addressData.variant"
              labelText="Variant"
              value={values.addressData.variant || ""}
              onChange={(e) => setFieldValue("addressData.variant", e.target.value)}
            />
          </MultiColumnRow> */}

          {/* <MultiColumnRow>
            <TextInput
              id="decimals"
              name="addressData.decimals"
              labelText="Decimals"
              value={values.addressData.decimals?.toString() || ""}
              onChange={(e) => setFieldValue("addressData.decimals", e.target.value)}
            />
          </MultiColumnRow> */}

          <MultiColumnRow>
            <TextInput
              id="description"
              name="credentialData.notes.description"
              labelText="Description"
              value={values.credentialData.notes?.description || ""}
              onChange={(e) => setFieldValue("credentialData.notes.description", e.target.value)}
            />
          </MultiColumnRow>

          {/* <MultiColumnRow>
            <TextInput
              id="contract"
              name="addressData.contract"
              labelText="Contract"
              value={values.addressData.contract || ""}
              onChange={(e) => setFieldValue("addressData.contract", e.target.value)}
            />
          </MultiColumnRow> */}
        </Form>
      )}
    </Formik>
  );
};

export default RoleEditForm;
