import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Credential } from "../../../lib/api/types";

export interface UpdateCredentialFormData {
  name: string;
  credentialData: Credential;
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateCredentialFormData): Promise<void> {
  await httpClientInstance.update("Credential", formData.name, {
    ...formData.credentialData,
  });
}

export function useModifyCredentialMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateCredentialFormData>,
): UseMutationResult<void, SubmitError, UpdateCredentialFormData> {
  return useMutation<void, SubmitError, UpdateCredentialFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateCredentialFormData, context: unknown) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateCredentialFormData, context: unknown) => {
      console.error("Error submitting credential modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
