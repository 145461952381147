import {
  Button,
  Modal,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CPage from "../../components/CPage";
import { useNotificationStore } from "../../stores/notifications";
import { formatDate } from "../../utils/formater";
import { useCredential, useDeleteCredential } from "./api/getCredentialPage";
import CredentialEditForm from "./components/CredentialEditForm";

const TransferDetail: React.FC = () => {
  const params = useParams<{ "*": string }>();
  const credentialId = params["*"] ?? "";
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const mutation = useDeleteCredential();

  const { data: credentialDetails, isLoading, error } = useCredential(credentialId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDelete = () => {
    // Handle delete logic here
    mutation.mutate(credentialId, {
      onSuccess: () => {
        navigate("/credentials");
        addNotification({
          type: "success",
          title: "Credential deleted successfully",
        });
      },
      onError: (error) => {
        addNotification({
          type: "error",
          title: "Error deleting credential",
          message: error.message,
        });
        console.error("Error deleting credential:", error.message);
      },
    });
  };

  useEffect(() => {
    if (error) {
      addNotification({
        type: "error",
        title: "Error loading transfer details",
        message: error.message,
      });
    }
  }, [error, addNotification]);

  return (
    <CPage title="Credential Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/credentials")}>
          Back to Credentials
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>

      <div>{isLoading ? "Loading..." : ""}</div>
      <div>{error ? "Error loading credentials details" : ""}</div>

      {!isLoading && !error && credentialDetails && (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Credential Name</StructuredListCell>
                    <StructuredListCell>{credentialDetails.name}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Creator</StructuredListCell>
                    <StructuredListCell>
                      {credentialDetails.creator ?? "Unknown"}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Create Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(credentialDetails.create_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Variant</StructuredListCell>
                    <StructuredListCell>{credentialDetails.variant}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Version</StructuredListCell>
                    <StructuredListCell>{credentialDetails.version}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Update Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(credentialDetails.update_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "1rem",
              fontSize: "0.875rem",
            }}
          >
            Public key: {credentialDetails.public_key}
          </div>
        </div>
      )}

      <Modal
        open={isModalOpen}
        modalHeading="Delete Credential"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
      >
        <p>Are you sure you want to Abort: {credentialId}?</p>
      </Modal>

      <Modal
        open={isEditModalOpen}
        modalHeading="Edit Credential"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsEditModalOpen(false)}
        onRequestSubmit={() => {
          // Trigger form submission programmatically
          document
            .getElementById("credential-edit-form")
            ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }}
      >
        <CredentialEditForm credentialId={credentialId} onClose={() => setIsEditModalOpen(false)} />
      </Modal>
    </CPage>
  );
};

export default TransferDetail;
