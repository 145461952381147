import { useMutation, useQuery } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { CredentialPage } from "../../../lib/api/types";
import { useMemo } from "react";
import { Credential } from "../../../lib/api/types";
import { Name } from "@/lib/api/name";

async function fetchCredentials(pageSize: number): Promise<CredentialPage> {
  return httpClientInstance.listCredential({ page_size: pageSize });
}

async function fetchCredential(name: Name): Promise<Credential> {
  return httpClientInstance.getCredential(name);
}

async function deleteCredential(name: Name) {
  return httpClientInstance.delete("Credential", name);
}

interface UseCredentialsPageOptions {
  pageSize?: number;
}

export function useCredentialsPage({ pageSize = 100 }: UseCredentialsPageOptions = {}) {
  const queryKey = ["credentials", pageSize];

  const { data, error, isLoading } = useQuery<CredentialPage, Error>({
    queryKey,
    queryFn: () => fetchCredentials(pageSize),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}

export function useCredential(name: string) {
  const queryKey = useMemo(() => ["credential", name], [name]);

  const { data, error, isLoading } = useQuery({
    queryKey,
    queryFn: () => fetchCredential(name),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}

export function useDeleteCredential() {
  return useMutation({
    mutationFn: deleteCredential,
  });
}
