import { ComboBox } from "@carbon/react";
import { Field, Formik } from "formik";

import { Form, ModalSubmitButton, MultiColumnRow, TextInput } from "../../../components/form";

import { useModalSetters } from "../../../components/CModal";

import { RoleFormData, useAddRoleMutation } from "../api/createRole";

export default function RoleCreateForm() {
  const modalSetters = useModalSetters();

  const addRoleMutation = useAddRoleMutation();

  const initialValues: RoleFormData = {
    name: "",
    credential: [],
    user: "human",
    labels: {},
    notes: {
      description: "",
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        const { setLoading, setNotif } = modalSetters;

        setLoading(true);
        const notif = { success: `Role created ${values.name} successfully.` };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isErrored = false;
        let errMsg = "";

        addRoleMutation.mutate(values, {
          onError: (err) => {
            errMsg = err.message;
            isErrored = true;
            setLoading(false);
            setNotif({ success: "", error: errMsg });
          },
          onSuccess: () => {
            setLoading(false);
            setNotif({ success: notif.success, error: "" });
          },
        });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ minHeight: 400 }}>
          <MultiColumnRow>
            <Field labelText="Name" name="name" as={TextInput} value={values.name || ""} />
          </MultiColumnRow>
          <MultiColumnRow>
            <Field
              id="user"
              name="user"
              component={ComboBox}
              titleText="User"
              items={
                ["human", "machine"].map((user) => ({
                  id: user,
                  label: user,
                })) || []
              }
              onChange={(event: any) => {
                const newUserValue = event.selectedItem.id;
                setFieldValue("user", newUserValue);
              }}
            />
          </MultiColumnRow>
          <MultiColumnRow>
            <Field
              id="credential"
              name="credential"
              component={ComboBox}
              titleText="Credential"
              items={
                ["session", "k256", "web-authn", "web-authn-uv", "ed255", "p256"].map(
                  (credType) => ({
                    id: credType,
                    label: credType,
                  }),
                ) || []
              }
              onChange={(event: any) => {
                const newCredValue = event.selectedItem.id;
                setFieldValue("credential", newCredValue);
              }}
            />
          </MultiColumnRow>
          <MultiColumnRow>
            <TextInput
              id="description"
              name="notes.description"
              labelText="Description"
              value={values.notes?.description || ""}
              onChange={(e: { target: { value: any } }) =>
                setFieldValue("notes.description", e.target.value)
              }
            />
          </MultiColumnRow>
          <ModalSubmitButton />
        </Form>
      )}
    </Formik>
  );
}
