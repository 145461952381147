import {
  Button,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CPage from "../../components/CPage";
import { ParseName } from "../../lib/api/name";
import { useNotificationStore } from "../../stores/notifications";
import { formatDate } from "../../utils/formater";
import { useOperationDetails } from "./api/getOperationDetails";
import "./OperationDetail.css";

const OperationDetail: React.FC = () => {
  const params = useParams<{ "*": string }>();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  const { data: operation, isLoading, error } = useOperationDetails(params["*"]!);

  useEffect(() => {
    if (error) {
      addNotification({
        type: "error",
        title: "Error loading operation details",
        message: error.message,
      });
    }
  }, [error, addNotification]);

  return (
    <CPage title="Operation Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/operations")}>
          Back to Operations
        </Button>
      </div>

      <div>{isLoading ? "Loading..." : ""}</div>
      <div>{error ? "Error loading operation details" : ""}</div>

      {!error && !isLoading && operation && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Operation ID</StructuredListCell>
                    <StructuredListCell>
                      {ParseName([operation.name ?? ""], "Operation").resourceId}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>State</StructuredListCell>
                    <StructuredListCell>{operation.state}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Resource</StructuredListCell>
                    <StructuredListCell>{operation.request?.resource}</StructuredListCell>
                  </StructuredListRow>

                  <StructuredListRow>
                    <StructuredListCell head>Create Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(operation?.create_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Initiator</StructuredListCell>
                    <StructuredListCell>{operation.initiator}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Action</StructuredListCell>
                    <StructuredListCell>{operation.request?.action}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Resource ID</StructuredListCell>
                    <StructuredListCell>{operation?.request?.id ?? "No ID"}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Update Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(operation?.update_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <div style={{ flex: 1, maxWidth: "49%" }}>
              <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>Request</h4>
              <pre
                style={{
                  backgroundColor: "#0f0f0f8a",
                  padding: "2rem",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
              >
                {JSON.stringify(JSON.parse(operation.request?.body ?? "{}"), null, 4)}
              </pre>
            </div>
            <div style={{ flex: 1, maxWidth: "49%" }}>
              <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>Response</h4>
              <pre
                style={{
                  backgroundColor: "#0f0f0f8a",
                  padding: "2rem",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
              >
                {operation.response ? JSON.stringify(operation.response, null, 4) : "No response"}
              </pre>
            </div>
          </div>
        </div>
      )}
    </CPage>
  );
};

export default OperationDetail;
