import CPage from "../../components/CPage";

import { useCredentialsPage } from "./api/getCredentialPage";
import CredentialTable from "./components/CredentialTable";

export default function CredentialsPage() {
  const { data: credentials } = useCredentialsPage();

  return (
    <CPage
      title="Credential"
      primaryAction={{
        title: "Create Credential",
        url: "/users/invite",
      }}
    >
      {credentials && credentials.credentials ? (
        <CredentialTable credentialPage={credentials} />
      ) : (
        <p>No Credentials</p>
      )}
    </CPage>
  );
}
