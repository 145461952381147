import OperationsTable from "./component/OperationsTable";
import CPage from "../../components/CPage";
import { Outlet } from "react-router-dom";

export default function OperationsPage() {
  return (
    <CPage title="Operations">
      <OperationsTable />
      <Outlet />
    </CPage>
  );
}
