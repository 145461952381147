import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddress } from "./api/getAddress";
import { formatDate } from "../../utils/formater";
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  Button,
  Modal,
} from "@carbon/react";
import CPage from "../../components/CPage";
import { useDeleteAddress } from "./api/deleteAddress";
import { useNotificationStore } from "../../stores/notifications";
import AddressEditForm from "./components/AddressEditForm";
import { ParseName } from "../../lib/api/name";
import AddressBalancesTable from "./components/AddressBalancesTable";
import { getExplorerUrl } from "../../utils/BlockchainExplorerManager";
import { Autoscaling } from "@carbon/react/icons";

const AddressDetail: React.FC = () => {
  const { addressId = "", chainId = "" } = useParams<{ addressId: string; chainId: string }>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { addNotification } = useNotificationStore();
  const { mutate: deleteAddress, isPaused: isDeleting } = useDeleteAddress();

  const {
    data: address,
    isLoading: isLoadingAddresses,
    error: errorAddresses,
  } = useAddress({
    addressId: `chains/${chainId}/addresses/${addressId}`,
  });

  console.log("addressesPage", address);
  console.log("isLoadingAddresses", isLoadingAddresses);
  console.log("errorAddresses", errorAddresses);

  function navigateToBlockChainExplorer(e: React.MouseEvent | React.KeyboardEvent) {
    e.stopPropagation();

    const url = getExplorerUrl(chainId, "address", addressId);

    window.open(url, "_blank", "noopener,noreferrer");
  }

  const handleDelete = () => {
    deleteAddress(
      { name: `chains/${chainId}/addresses/${addressId}` },
      {
        onSuccess: () => {
          navigate("/accounts");
          addNotification({
            type: "success",
            title: "Account deleted successfully",
          });
        },
        onError: (error) => {
          addNotification({
            type: "error",
            title: "Error deleting account",
            message: error.message,
          });
          console.error("Error deleting account:", error.message);
        },
      },
    );
  };

  if (isLoadingAddresses) {
    return <div>Loading...</div>;
  }

  if (errorAddresses) {
    return <div>Error loading address details: {errorAddresses.message}</div>;
  }

  if (!address) {
    return <div>No address details available</div>;
  }

  return (
    <CPage title="Address Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/addresses")}>
          Back to Addresses
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>
      <StructuredListWrapper>
        <StructuredListHead>{/* Optionally add header content here */}</StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell head>Account</StructuredListCell>
            <StructuredListCell>
              {address.account && ParseName(address.account, "Account").resourceId}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Address</StructuredListCell>
            <StructuredListCell>
              <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
                <span>{address.address}</span>
                <span
                  onClick={navigateToBlockChainExplorer}
                  onKeyDown={navigateToBlockChainExplorer}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                  title="Navigate to explorer"
                >
                  <Autoscaling />
                </span>
              </div>
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Description</StructuredListCell>
            <StructuredListCell>{address.notes?.description || ""}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Create Time</StructuredListCell>
            <StructuredListCell>{formatDate(address.create_time as string)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Update Time</StructuredListCell>
            <StructuredListCell>{formatDate(address.update_time as string)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Key</StructuredListCell>
            <StructuredListCell>{address.key}</StructuredListCell>
          </StructuredListRow>

          <StructuredListRow>
            <StructuredListCell head>Variant</StructuredListCell>
            <StructuredListCell>{address.variant}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Version</StructuredListCell>
            <StructuredListCell>{address.version}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>

      <h3
        style={{
          padding: "2rem 0 1rem",
        }}
      >
        Balances
      </h3>
      <AddressBalancesTable addressId={addressId} chainId={chainId} />

      <Modal
        open={isModalOpen}
        modalHeading="Confirm Delete"
        primaryButtonText={isDeleting ? "Deleting..." : "Delete"}
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
        primaryButtonDisabled={isDeleting}
      >
        <p>Are you sure you want to delete: {address.address}?</p>
      </Modal>

      <Modal
        open={isEditModalOpen}
        modalHeading="Edit Account"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsEditModalOpen(false)}
        onRequestSubmit={() => {
          // Trigger form submission programmatically
          document
            .getElementById("address-edit-form")
            ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }}
      >
        <AddressEditForm
          addressName={`chains/${chainId}/addresses/${addressId}`}
          onClose={() => setIsEditModalOpen(false)}
        />
      </Modal>
    </CPage>
  );
};

export default AddressDetail;
