import React from "react";
import { Navigate, redirect, useRouteError } from "react-router-dom";
import CLayout from "../components/layout/CHeaderMenu";
import AccountCreateModal from "../features/accountsManagement/components/AccountCreateModal";
import AddressCreateModal from "../features/accountsManagement/components/AddressCreateModal";
import ApprovalsPage from "../features/approvalsManagement/ApprovalsPage";
import AssetsPage from "../features/assetManagement/AssetsPage";
import DepositsPage from "../features/depositsManagement/DepositsPage";
import PolicyPage from "../features/policyManagement/PolicyPage";
import PolicyCreateModal from "../features/policyManagement/components/PolicyCreateModal";
import TransfersPage from "../features/transfersManagement/TransfersPage";
import TransferCreateModal from "../features/transfersManagement/component/TransferCreateModal";
// import SettingsPage from "../features/settings/SettingsPage";
import { useUser } from "@clerk/clerk-react";
import { GetClerkPublishableKey, KEY_USER, QUERY_KEY_REDIRECT_TO } from "lib/constants";
import AccountDetail from "../features/accountsManagement/AccountDetail";
import AccountsPage from "../features/accountsManagement/AccountsPage";
import AddressDetail from "../features/accountsManagement/AddressDetail";
import AddressPage from "../features/accountsManagement/AddressPage";
import LinkAddressModal from "../features/accountsManagement/components/LinkAddressModal";
import ApprovalDetails from "../features/approvalsManagement/ApprovalDetails";
import AssetDetail from "../features/assetManagement/AssetDetail";
import AssetCreateModal from "../features/assetManagement/components/AssetCreateModal";
import SignInPage from "../features/clerk/SignInPage";
import DepositDetails from "../features/depositsManagement/DepositDetails";
import OperationDetails from "../features/operationsManagement/OperationDetails";
import OperationsPage from "../features/operationsManagement/OperationsPage";
import OrgTreasuryEndPointSelectorModal from "../features/organizationManagement/OrgTreasuryEndPointSelectorModal";
import OrgTreasuryUserSelectorModal from "../features/organizationManagement/OrgTreasuryUserSelectorModal";
import PolicyDetails from "../features/policyManagement/PolicyDetail";
import PolicyCreator from "../features/policyManagement/components/PolicyCreator";
import TransferDetails from "../features/transfersManagement/TransferDetails";
import CredentialDetails from "../features/usersManagement/CredentialDetails";
import CredentialsPage from "../features/usersManagement/CredentialsPage";
import RegisterCredentialsPage from "../features/usersManagement/RegisterCredentialsPage";
import RoleDetail from "../features/usersManagement/RoleDetails";
import RolesPage from "../features/usersManagement/RolesPage";
import UsersPage from "../features/usersManagement/UsersPage";
import RoleCreateModal from "../features/usersManagement/components/RoleCreateModal";
import UserDetails from "../features/usersManagement/UserDetails";
import UserCreateModal from "../features/usersManagement/components/UserCreateModal";
import UserGenerateInviteModal from "../features/usersManagement/components/UserGenerateInviteModal";
import UserLinkAccountModal from "../features/usersManagement/components/UserLinkAccount";
import useCachedState from "../hooks/useCachedState";
import useValidatedApiUrl from "../hooks/useValidatedApiUrl";

function ErrorElement() {
  const error: any = useRouteError();
  return <CLayout error={error?.data || ""} />;
}

interface ProtectedRouteProps {
  children: React.ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps): JSX.Element {
  const { isSignedIn } = useUser();
  const [apiURL] = useValidatedApiUrl();
  const [cachedCordialUser] = useCachedState<string>(KEY_USER, "");
  // const { session } = useSession();
  // useAccessToken(session);

  const PUBLISHABLE_KEY: string = GetClerkPublishableKey();
  console.log("PUBLISHABLE_KEY", PUBLISHABLE_KEY);
  // Bypass clerk if it is not disabled.

  //return <>{children}</>;
  if (!window.CLERK_DEV) {
    if (isSignedIn === undefined) {
      // clerk isn't finished loading yet.
      // Should we show some sort of loading indicator here?
      return <>{children}</>;
    } else if (isSignedIn === false) {
      // not signed in -- switch to login page and save current url/path.
      // capture the current URL we want to redirect to
      const params = new URLSearchParams(window.location.search);
      let redirect_to = window.location.pathname;
      if (redirect_to.startsWith("/sign-in")) {
        redirect_to = "/";
      }
      params.set(QUERY_KEY_REDIRECT_TO, redirect_to + window.location.search);
      return <Navigate to={"/sign-in?" + params.toString()} replace />;
    }
  }

  // We are logged in.
  // we should set API endpoint if it's not set yet.
  if (apiURL === "") {
    return <Navigate to={"/selectEndpoint" + window.location.search} replace />;
  }

  // we should select user if it's not selected yet.
  if (cachedCordialUser === "") {
    return <Navigate to={"/selectUser" + window.location.search} replace />;
  }

  return <>{children}</>;
}

export default function createRoutes() {
  return [
    {
      Component: CLayout,

      errorElement: <ErrorElement />,
      children: [
        { path: "sign-in", element: <SignInPage /> },
        {
          path: "selectEndpoint",
          Component: OrgTreasuryEndPointSelectorModal,
        },
        {
          path: "selectUser",
          Component: OrgTreasuryUserSelectorModal,
        },
        {
          path: "/",
          // don't drop any query params if we redirect
          loader: () => redirect("/transfers" + window.location.search),
        },
        {
          path: "transfers",
          element: (
            <ProtectedRoute>
              <TransfersPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: TransferCreateModal,
            },
          ],
        },
        {
          path: "transfers/:id",
          element: (
            <ProtectedRoute>
              <TransferDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "deposits",
          element: (
            <ProtectedRoute>
              <DepositsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "deposits/*",
          element: (
            <ProtectedRoute>
              <DepositDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "accounts",
          element: (
            <ProtectedRoute>
              <AccountsPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: AccountCreateModal,
            },

            {
              path: "link-addresses",
              Component: LinkAddressModal,
            },
          ],
        },
        {
          path: "accounts/:accountId",
          element: (
            <ProtectedRoute>
              <AccountDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "Addresses",
          element: (
            <ProtectedRoute>
              <AddressPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: AddressCreateModal,
            },
          ],
        },
        {
          path: "address/chains/:chainId/addresses/:addressId",
          element: (
            <ProtectedRoute>
              <AddressDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "assets",
          element: (
            <ProtectedRoute>
              <AssetsPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: AssetCreateModal,
            },
            // {
            //   path: "view/:id",
            //   Component: AssetViewModal,
            // },
          ],
        },
        {
          path: "assets/*",
          element: (
            <ProtectedRoute>
              <AssetDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "policy",
          element: (
            <ProtectedRoute>
              <PolicyPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: PolicyCreateModal,
            },
            // {
            //   path: "view/:id",
            //   Component: PolicyViewModal,
            // },
          ],
        },
        {
          path: "policy/creator",
          element: (
            <ProtectedRoute>
              <PolicyCreator />
            </ProtectedRoute>
          ),
        },
        {
          path: "policy/transfer-rules/:id",
          element: (
            <ProtectedRoute>
              <PolicyDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "approvals",
          element: (
            <ProtectedRoute>
              <ApprovalsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "approvals/:id",
          element: (
            <ProtectedRoute>
              <ApprovalDetails />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: "settings",
        //   element: (
        //     <ProtectedRoute>
        //       <SettingsPage />
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: "users",
          element: (
            <ProtectedRoute>
              <UsersPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: UserCreateModal,
            },
            {
              path: "invite",
              Component: UserGenerateInviteModal,
            },
            {
              path: "link-user",
              Component: UserLinkAccountModal,
            },
          ],
        },
        {
          path: "users/*",
          element: (
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "register-credentials",
          element: (
            <ProtectedRoute>
              <RegisterCredentialsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "roles",
          element: (
            <ProtectedRoute>
              <RolesPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "create",
              Component: RoleCreateModal,
            },
          ],
        },
        {
          path: "roles/:id",
          element: (
            <ProtectedRoute>
              <RoleDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "credentials",
          element: (
            <ProtectedRoute>
              <CredentialsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "credentials/*",
          element: (
            <ProtectedRoute>
              <CredentialDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "operations",
          element: (
            <ProtectedRoute>
              <OperationsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "operations/*",
          element: (
            <ProtectedRoute>
              <OperationDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];
}
