import { Outlet } from "react-router-dom";
import CPage from "../../components/CPage";

import { useRole } from "./api/getRoles";
import RoleTable from "./components/RoleTable";

export default function RolesPage() {
  const { data: role } = useRole();

  return (
    <CPage title="Roles" primaryAction={{ title: "Add role", url: "./create" }}>
      {role && role.roles ? <RoleTable rolePage={role} /> : <p>No Roles</p>}
      <Outlet />
    </CPage>
  );
}
