import {
  Button,
  Modal,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CPage from "../../components/CPage";
import { useNotificationStore } from "../../stores/notifications";
import { formatDate } from "../../utils/formater";
import { useDeleteUser, useUserById } from "./api/useUsers";

const UserDetails: React.FC = () => {
  const params = useParams<{ "*": string }>();
  const userId = params["*"] ?? "";
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const mutation = useDeleteUser();

  const user = useUserById(userId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    // Handle delete logic here
    mutation.mutate(userId, {
      onSuccess: () => {
        navigate("/users");
        addNotification({
          type: "success",
          title: "User deleted successfully",
        });
      },
      onError: (error) => {
        addNotification({
          type: "error",
          title: error.name,
          message: error.message,
        });
        console.error("Error deleting user:", error.message);
      },
    });
  };

  return (
    <CPage title="User Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/users")}>
          Back to Users
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>

      {user && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>User Name</StructuredListCell>
                    <StructuredListCell>{user.name}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Creator</StructuredListCell>
                    <StructuredListCell>{user.creator ?? "Unknown"}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Create Time</StructuredListCell>
                    <StructuredListCell>{formatDate(user.create_time ?? "")}</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Variant</StructuredListCell>
                    <StructuredListCell>{user.variant}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Roles</StructuredListCell>
                    <StructuredListCell>{user.roles?.join(", ")}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Update Time</StructuredListCell>
                    <StructuredListCell>{formatDate(user.update_time ?? "")}</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <div style={{ flex: 1, maxWidth: "49%" }}>
              <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>Notes</h4>
              <pre
                style={{
                  backgroundColor: "#0f0f0f8a",
                  padding: "2rem",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
              >
                {user.notes ? JSON.stringify(user.notes, null, 4) : "No notes"}
              </pre>
            </div>
            <div style={{ flex: 1, maxWidth: "49%" }}>
              <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>Labels</h4>
              <pre
                style={{
                  backgroundColor: "#0f0f0f8a",
                  padding: "2rem",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
              >
                {user.labels ? JSON.stringify(user.labels, null, 4) : "No labels"}
              </pre>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={isModalOpen}
        modalHeading="Delete User"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
      >
        <p>Are you sure you want to delete: {userId}?</p>
      </Modal>
    </CPage>
  );
};

export default UserDetails;
