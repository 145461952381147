import { TextInput } from "@carbon/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";

import { MultiColumnRow } from "../../../components/form";
import { useNotificationStore } from "../../../stores/notifications";
import { useRoleById } from "../api/getRoles";
import { UpdateRoleFormData, useModifyRoleMutation } from "../api/updateRole";

interface RoleEditFormProps {
  roleId: string;
  onClose: () => void;
}

const RoleEditForm: React.FC<RoleEditFormProps> = ({ roleId, onClose }) => {
  const { mutate } = useModifyRoleMutation();
  const { addNotification } = useNotificationStore();

  const { data: role, isLoading, error } = useRoleById(roleId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading role details...</div>;
  }

  const initialValues: UpdateRoleFormData = {
    name: role?.name || "",
    roleData: role || {},
  };

  const onSubmit = async (
    values: UpdateRoleFormData,
    { setSubmitting }: FormikHelpers<UpdateRoleFormData>,
  ) => {
    try {
      mutate(values, {
        onError: (err: any) => {
          addNotification({ type: "error", title: "Error", message: `Error: ${err.message}` });
        },
        onSuccess: () => {
          addNotification({
            type: "success",
            title: "Success",
            message: "Role updated successfully",
          });
          onClose();
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form id="role-edit-form">
          <MultiColumnRow>
            <h5 style={{ paddingBottom: "15px" }}>{values.name || "NA"}</h5>
          </MultiColumnRow>
          <MultiColumnRow>
            <TextInput
              id="description"
              name="roleData.notes.description"
              labelText="Description"
              value={values.roleData.notes?.description || ""}
              onChange={(e) => setFieldValue("roleData.notes.description", e.target.value)}
            />
          </MultiColumnRow>
        </Form>
      )}
    </Formik>
  );
};

export default RoleEditForm;
