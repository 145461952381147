import { useMutation } from "@tanstack/react-query";
import CPage from "../../components/CPage";

import { useUser } from "./api/useUsers";
import { registerInviteCode } from "./services/registerInviteCode";
import { useNotificationStore } from "../../stores/notifications";
import { Form, Formik } from "formik";
import { MultiColumnRow, TextInput } from "../../components/form";
import CButton from "../../components/CButton";
import { useSearchParams } from "react-router-dom";

const validate = (setSearchParams: any) => (values: any) => {
  const errors: any = {};
  const searchParams: any = {};

  if (!values.code) {
    errors.code = "Required";
  } else {
    searchParams.code = values.code;
  }

  if (Object.keys(searchParams).length > 0) {
    setSearchParams(searchParams);
  }
  return errors;
};

export default function RegisterCredentialsPage() {
  const currentUser = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = {
    code: searchParams.get("code") || "",
  };

  const { addNotification } = useNotificationStore();

  const postCrendentialMutation = useMutation({
    mutationFn: async (invitationCode: string) => {
      return registerInviteCode(currentUser!, invitationCode);
    },
  });

  return (
    <CPage title="Register Credentials">
      {currentUser && currentUser.name ? (
        <div>
          <Formik
            initialValues={initialValues}
            validate={validate(setSearchParams)}
            validateOnMount={true}
            onSubmit={(values, { resetForm }) => {
              postCrendentialMutation.mutate(values.code, {
                onError: (error) => {
                  console.log("failure you did not register a credential", error);
                  addNotification({
                    type: "error",
                    title: `${error}`,
                  });
                },
                onSuccess: () => {
                  console.log("success you registered a credential");
                  addNotification({
                    type: "success",
                    title: `Credential Registered successfully`,
                  });
                  setSearchParams({});
                  resetForm({
                    values: {
                      code: "",
                    },
                  });
                },
              });
            }}
          >
            <Form style={{ maxWidth: 400 }}>
              <MultiColumnRow>
                <TextInput name="code" type="text" labelText="" placeholder="Invite code" />
                <div>
                  <CButton size="md" kind="secondary" type="submit">
                    Register security key
                  </CButton>
                </div>
              </MultiColumnRow>
            </Form>
          </Formik>
          <span style={{ fontSize: "12px" }}> An admin can create an invite code for you.</span>
        </div>
      ) : (
        <p>No Credentials</p>
      )}
    </CPage>
  );
}
