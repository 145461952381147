import { useQuery } from "@tanstack/react-query";
import { cordialApisClient } from "../../../lib/cordialapis/api";
import { useMemo } from "react";
import useTreasury from "../../../features/treasuryManagement/getTreasuries";
import { AddressBalancePage } from "../../../lib/cordialapis/oracle/types";
import { ParseName } from "../../../lib/api/name";

function fetchAddressBalances(
  addressId: string,
  chainId: string,
  pageSize: number,
  pageToken?: string,
  filter?: string,
  treasuryId?: string,
): Promise<AddressBalancePage> {
  return cordialApisClient.fetchAddressBalances(addressId, chainId, treasuryId, {
    page_size: pageSize,
    page_token: pageToken,
    filter,
  });
}

interface UseAddressBalancesOptions {
  addressId: string;
  chainId: string;
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export function useAddressBalances({
  addressId,
  chainId,
  pageSize = 100,
  pageToken,
  filter,
}: UseAddressBalancesOptions) {
  const { data: treasuryPage, isLoading: isLoadingTreasury, error: errorTreasury } = useTreasury();

  const treasuryId = treasuryPage?.treasuries?.[0]?.name
    ? ParseName(treasuryPage?.treasuries[0].name, "Treasury").resourceId
    : undefined;

  const queryKey = useMemo(
    () => ["Address", addressId, chainId, pageSize, pageToken, filter],
    [addressId, chainId, filter, pageSize, pageToken],
  );

  const { data, error, isLoading, isError } = useQuery<AddressBalancePage, Error>({
    queryKey,
    queryFn: () =>
      fetchAddressBalances(addressId, chainId, pageSize, pageToken, filter, treasuryId),
    enabled: !isLoadingTreasury && !errorTreasury,
    refetchInterval: 100000,
  });

  return {
    data: data || null,
    error: isError ? error : errorTreasury ?? null,
    isLoading: isLoading || isLoadingTreasury,
  };
}
